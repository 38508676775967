<template>
  <div>
    <v-card color="grey lighten-4" flat tile style="margin-top: 20px">
      <v-toolbar>
        <v-toolbar-title> Cliente #{{ numCliente }} </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn @click="goBack()">
          <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
        </v-btn>
      </v-toolbar>
      <p style="margin-top: 15px; padding-bottom: 10px">
        {{
          this.nombres +
            " " +
            this.apellidoPaterno +
            " " +
            (this.apellidoMaterno ? this.apellidoMaterno : "")
        }}
      </p>
    </v-card>
    <v-divider></v-divider>

    <v-row>
      <v-col cols="7">
        <v-form>
          <v-row>
            <v-col>
              <v-stepper non-linear>
                <v-stepper-header>
                  <v-stepper-step step="1" editable>
                    Datos del Cliente
                  </v-stepper-step>
                  <v-spacer></v-spacer>

                  <v-btn
                    v-if="!showEdit"
                    icon
                    class="mr-3 mt-5"
                    @click="showEdit = true"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- 1ST ITEM (FORM) -->
                  <v-stepper-content step="1">
                    <cliente-form
                      v-if="showEdit"
                      :cliente="cliente"
                      @cancel="showEdit = false"
                      @clienteSaved="clienteSaved"
                    ></cliente-form>

                    <v-row v-else style="padding: 20px">
                      <v-col cols="4">
                        <v-text-field
                          label="Estatus del cliente"
                          v-model="status"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Número de cliente"
                          v-model="numCliente"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Regimen"
                          v-model="regimen"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <!-- End 2nd Col -->
                      <v-col cols="4">
                        <v-text-field
                          label="Nombre(s)"
                          v-model="nombres"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Apellido Paterno"
                          v-model="apellidoPaterno"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Apellido Materno"
                          v-model="apellidoMaterno"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          label="Genero"
                          v-model="genero"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Fecha de Nacimiento"
                          v-model="fechaNacimiento"
                          prepend-icon="mdi-calendar"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Estado Civil"
                          v-model="estadoCivilSelected"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col cols="4" v-if="regimen != 'Física'">
                        <v-text-field
                          label="Nombre de la Empresa"
                          v-model="nombreEmpresa"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="regimen != 'Física'">
                        <v-text-field
                          label="Razon social"
                          v-model="razonSocial"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="regimen != 'Física'">
                        <v-text-field
                          label="Fecha de Constitucion"
                          v-model="fechaConstitucion"
                          prepend-icon="mdi-calendar"
                          readonly
                        ></v-text-field>
                      </v-col> -->

                      <v-col cols="4">
                        <v-text-field
                          label="RFC"
                          v-model="rfc"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          label="Correo Electrónico"
                          v-model="correoElectronico"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Teléfono"
                          v-model="celular"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="4">
                        <v-text-field
                          label="Telefono de oficina"
                          v-model="telefonoOficina"
                          readonly
                        ></v-text-field>
                      </v-col> -->
                      <v-col cols="4">
                        <v-text-field
                          label="Agente"
                          v-model="agente"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          label="Creado por"
                          v-model="creadoPor"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="Fecha de creacion"
                          v-model="fechaCreacion"
                          prepend-icon="mdi-calendar"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="Modificado por"
                          v-model="modificadoPor"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="Ultima modificacion"
                          v-model="ultimaModificacion"
                          prepend-icon="mdi-calendar"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

      <v-col cols="5">
        <v-card style="margin-bottom: 40px" class="pt-1">
          <v-row>
            <v-col cols="10">
              <v-subheader>NOTAS Y DOCUMENTOS</v-subheader>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-data-table
            :headers="columnsDocumentos"
            :items="documentos"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
          >
            <template v-slot:top>
              <!-- <v-text-field
                                      v-model="search"
                                      label="Buscar"
                                      class="mx-4"
                                      ></v-text-field> -->
            </template>
            <template v-slot:item.url="{ item }">
              <v-icon small class="mr-2" @click="verDocumento(item)">
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <v-card style="margin: 15px 0 25px 0">
          <v-row>
            <v-col cols="10">
              <v-subheader>EMAILS</v-subheader>
            </v-col>
            <!-- <v-col cols="4">
                    <v-btn icon>
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col> -->
          </v-row>
          <v-divider></v-divider>
          <v-data-table
            :headers="columns"
            :items="correos"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
          >
            <template v-slot:top>
              <v-text-field label="Buscar" class="mx-4"></v-text-field>
            </template>
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.asunto }}</td>
                <td>{{ row.item.fecha }}</td>
                <td>{{ row.item.remitente }}</td>
                <td style="cursor:pointer;" @click="verDetalleCorreo(row.item)">
                  <v-icon light small class="mr-2">mdi-eye</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1"
              >COTIZACIONES/POLIZAS</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-divider></v-divider>

          <v-data-table
            :headers="columnsPolizas"
            :items="customerPolicies"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
            :items-per-page="5"
          >
            <template #[`item.productoId`]="{ item }">
              {{ obtenerNombreProducto(item.productoId) }}
            </template>

            <template v-slot:[`item.accion`]="{ item }">
              <v-btn icon @click="$router.push('/emision/' + item.id)">
                <v-icon small class="mr-2">mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { mainAxios } from "../../../mainAxios";

export default {
  components: {
    "cliente-form": () => import("@/components/cliente/editarClienteForm"),
  },

  props: {
    identificador: {
      type: Number,
      required: true,
    },
    producto: {
      type: String,
      required: true,
    },
    productoAseguradora: {
      type: String,
      required: true,
    },
    corte: {
      type: String,
      required: true,
    },
    restringirVenta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      polizasCLiente: [],
      customerPolicies: [],
      dialog: false,
      cliente: null,
      showEdit: false,
      clienteOnCall: false,
      documentsDialog: false,
      newCorreoDialog: false,
      dialogDetalleDocumentos: false,
      dialogCorreo: false,
      detallesDocumento: {},
      correoOpen: {},
      accionGuardar: false,
      roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
      clientes: [],
      correos: [],
      documentos: [],
      agentes: [],
      columns: [
        {
          text: "Asunto",
          align: "start",
          sortable: false,
          value: "asunto",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Para", value: "remitente" },
      ],
      columnsPolizas: [
        {
          text: "Certificado",
          align: "start",
          sortable: false,
          value: "poliza",
        },
        { text: "Estatus", value: "statusNombre" },
        { text: "Plan", value: "productoId" },
        { text: "ver", value: "accion" },
      ],
      columnsDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Tipo", value: "tipo" },
        { text: "Descripción", value: "descripcion" },
        { text: "Creado por", value: "creadoPor" },
        { text: "Fecha", value: "fecha" },
        { text: "Ver", value: "url" },
      ],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
      ],
      tipoItems: ["Nota", "Documento"],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Contrato firmado del Agente",
        "Cotización",
        "Cédula del Agente",
        "Identificación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      bodyClientes: null,
      uuid: this.$route.params.uuid,
      numeroClientes: null,
      estadoClientes: "",
      tipo: "",
      procesoCotizacion: "",
      isAgent: false,

      numCliente: null,

      origenClientesSelected: "",
      estadoSelected: "",
      tipoSelected: "",
      categoriaSelected: "",
      agente: "",
      productoSelected: "",
      productoId: "",
      ejecutivo: "",
      seguimientoClientes: "",
      polizaRelacionada: "",
      oportunidades: "",
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimiento: "",
      selectedGenero: "",
      celular: "",
      correoElectronico: "",
      edad: "",
      direccion: "",
      coberturasSelected: "",
      formaPagoSelected: "",
      estadoRepublicaSelected: "",
      estadoRepublicaId: "",
      estadoCivilSelected: "",
      codigoPostal: "",
      clientesCompletaSelected: "",
      rfc: "",
      scrollInvoked: 0,
      direccionFacturacion: "",
      direccionRecidencial: "",

      status: "",
      regimen: "",
      genero: "",
      nombreEmpresa: "",
      razonSocial: "",
      fechaConstitucion: "",
      telefonoOficina: "",
      creadoPor: "",
      fechaCreacion: "",
      modificadoPor: "",
      ultimaModificacion: "",

      current_poliza: null,
      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
    };
  },
  watch: {
    clienteOnCall(val) {
      if (!val) return;

      setTimeout(() => (this.clienteOnCall = false), 4000);
    },
  },
  methods: {
    ...mapActions("polizas", ["getPolizas"]),
    goBack() {
      this.clienteOnCall = false;
      return this.$router.go(-1);
    },
    onScroll() {
      this.scrollInvoked++;
    },
    verDetalleCorreo(correo) {
      console.log("Correo open:", correo);
      this.dialogCorreo = true;
      this.correoOpen = correo;
    },
    verDocumento(documento) {
      this.dialogDetalleDocumentos = true;
      this.detallesDocumento = documento;
    },
    obtnerDatosClientes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(`v1/cliente/uuid/${this.uuid}`, config)
        .then((response) => {
          this.clientes.push(response.data);
          this.cliente = response.data;
          this.bodyClientes = response.data.body
            ? JSON.parse(response.data.body)
            : "";
          response.data.correos
            ? response.data.correos.map((element) => {
                this.correos.push({
                  asunto: element.subject,
                  fecha: element.fecha
                    ? moment
                        .utc(element.fecha)
                        .subtract(6, "hours")
                        .format("DD/MM/YYYY HH:mm")
                    : null,
                  cuerpo: element.cuerpo,
                  remitente: element.remitente,
                  documentos: element.documentos,
                });
              })
            : "";
          response.data.documentosCarga
            ? response.data.documentosCarga.map((element) => {
                this.documentos.push({
                  nombre: element.nombre ? element.nombre : "No Disponible",
                  fecha: element.fecha
                    ? moment
                        .utc(element.fecha)
                        .subtract(6, "hours")
                        .format("DD/MM/YYYY HH:mm")
                    : "No Disponible",
                  tipo: element.tipo ? element.tipo : "No Disponible",
                  tipoDocumento: element.tipoDocumento
                    ? element.tipoDocumento
                    : "No Disponible",
                  descripcion: element.nota ? element.nota : "No Disponible",
                  creadoPor: element.creadoPor
                    ? element.creadoPor
                    : "No Disponible",
                  url: element.url,
                });
              })
            : "";
          (this.numCliente = response.data.id),
            (this.nombres = response.data.nombre ? response.data.nombre : "");
          this.apellidoPaterno = response.data.apellidoPaterno
            ? response.data.apellidoPaterno
            : "";
          this.apellidoMaterno = response.data.apellidoMaterno
            ? response.data.apellidoMaterno
            : "";
          this.fechaNacimiento = response.data.fechaNacimiento
            ? moment(response.data.fechaNacimiento).format("DD/MM/YYYY")
            : "";
          this.status = response.data.status
            ? parseInt(response.data.status) == -1
              ? "Baja"
              : parseInt(response.data.status) == 1
              ? "Activo"
              : parseInt(response.data.status) == 2
              ? "Inactivo"
              : "Nuevo"
            : "Nuevo";
          this.celular = response.data.telefonoMovil
            ? response.data.telefonoMovil
            : "";
          this.telefonoOficina = response.data.telefonoOficina
            ? response.data.telefonoOficina
            : "";
          this.correoElectronico = response.data.correo
            ? response.data.correo
            : "";
          this.estadoCivilSelected = response.data.estadoCivil
            ? response.data.estadoCivil
            : "";
          this.rfc = response.data.rfc ? response.data.rfc : "";
          this.genero = response.data.genero
            ? response.data.genero == "M"
              ? "Masculino"
              : "Femenino"
            : "";
          this.nombreEmpresa = response.data.nombreEmpresa
            ? response.data.nombreEmpresa
            : "";
          this.razonSocial = response.data.razonSocial
            ? response.data.razonSocial
            : "";
          this.regimen = response.data.regimen ? response.data.regimen : "";
          this.fechaConstitucion = response.data.fechaConstitucion
            ? moment(response.data.fechaConstitucion).format("DD/MM/YYYY")
            : "";
          this.creadoPor = response.data.creadoPor
            ? response.data.creadoPor
            : "No Disponible";
          this.fechaCreacion = response.data.fechaCreacion
            ? moment
                .utc(response.data.fechaCreacion)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : "No Disponible";
          this.modificadoPor = response.data.modificadoPor
            ? response.data.modificadoPor
            : "No Disponible";
          this.ultimaModificacion = response.data.ultimaModificacion
            ? moment
                .utc(response.data.ultimaModificacion)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : "No Disponible";
          this.agenteId = response.data.agenteId ? response.data.agenteId : "";
          this.getPolizasCliente();
        });
      this.obtenerProductos();
      this.obtenerAgentes();
      this.obtenerEstadoRepublica();
    },
    formatDate(date) {
      let formattedDate = new Date(
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
      return formattedDate;
    },
    obtenerEstadoRepublica() {
      this.estados.map((element) => {
        // console.log(element);
        this.estadoRepublicaId == element.id
          ? (this.estadoRepublicaSelected = element.estadoRepublica)
          : "";
      });
      // for (let i = 0; i < this.estados.length; i++) {
      //   if (typeof (this.estados[i]) === 'object') {
      //     try {
      //       this.estados[i] = JSON.parse(JSON.stringify(this.estados[i]));
      //       this.estadoRepublicaId == this.estados[i] ? this.estadoRepublicaSelected = this.estados[i].estadoRepublica : ''
      //     } catch (error) {
      //       console.error(error);
      //     }
      //   }
      //   console.log(this.estados);
      // }
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`,
            id: element.id,
            uuid: element.uuid,
          });
        });

        this.agentes.map((element) => {
          this.agenteId == element.id ? (this.agente = element.nombre) : "";
        });
      });
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/producto/list", config).then((response) => {
        // console.log('Productos: ', response.data);
        response.data.map((element) => {
          this.productos.push({
            nombre: `${element.ramo}`+ ' / ' + `${element.nombre}` + ' / ' + `${element.nombreAseguradora}`,
            id: element.id
          })

          this.productoId == element.id
            ? (this.productoSelected =
                `${element.ramo.toUpperCase()}` +
                " / " +
                `${element.nombre.toUpperCase()}` +
                " / " +
                `${element.nombreAseguradora.toUpperCase()}`)
            : "";
        });
      });
    },
    infoCompleta() {
      if (this.clientesCompletaSelected == 1) {
        return "Si";
      } else if (this.clientesCompletaSelected == 0) {
        return "No";
      }
    },
    async getPolizasCliente() {
      //CONSULTAR LA POLIZA DEL CLIENTE
      const response = await mainAxios.post(
        "/v1/emision/list/filtros",
        { cliente: this.cliente.id },
        this.axiosConfig
      );
      this.polizasCLiente = response.data;
      this.customerPolicies = response.data;
      console.log({ polizas: this.polizasCLiente });
    },
    openPoliza(item) {
      window.open(`/emision/${item.id}`);
    },

    clienteSaved() {
      window.location.reload();
    },

    obtenerNombreProducto(id) {
      if (id == "No Disponible") {
        return id;
      } else {
        let producto = this.productos.find((element) => element.id == id);
        if (producto) {
          return producto.nombre;
        } else {
          ("No disponible");
        }
      }
    },
  },
  mounted() {
    localStorage.removeItem("onCall");
    this.estados,
      this.obtnerDatosClientes(),
      this.infoCompleta(),
      this.obtenerAgentes();
  },
  computed: {
    ...mapState("polizas", ["polizas", "productos"]),
  },
};
</script>

<style scoped>
.v-stepper__step__step {
  background-color: white;
}
</style>
